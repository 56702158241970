// scss-lint:disable QualifyingElement

//
// Base styles
//

.btn {
  display: block;
  max-width: 164px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $input-btn-border-width solid transparent;
  @include button-size(0, 0, 15px, 35px, 12px);
  @include transition($btn-transition);
  height: 38px;
  font-weight: bold;
  text-decoration: none;
  @include bgs;
  cursor: pointer;
  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
  &:hover{
      opacity: .95;
    }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: .65;
    @include box-shadow(none);
  }

  &:active,
  &.active {
    background-image: none;
    @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }

}
  .btn-primary{
    background-image: url(../img/btn-primary.png);
    color: #186185 ;
    box-shadow: 0 3px 54px rgba(27, 162, 186, 0.85);
    &:focus{
      background-color: #65A1CD;
        // color: #186185 ;
    }

  }
  .btn-danger{
    background-image: url(../img/btn-danger.png);
    box-shadow: 0 3px 54px rgba(186, 27, 139, 0.85);
     &:focus{
      background-color: #BC1D5A;
    }
  }

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $white);
    }
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;
  border-radius: 0;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: $btn-block-spacing-y;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
