.top-nav {
  background: linear-gradient(to top,rgba(0,0,0,0.12) 0, rgba(0,0,0,0) 30%);
	background-color: #fff; 
	 
  &__nav{
  	 margin: 0 -10px;
  }
  &__item{
  	display: inline-block;
  	list-style-type: none;
    padding: 0 10px;
    position: relative;
    border-right: 1px solid #ddd;
    &:last-child{
      border: none;
    }
  }
  &__link{
    display: block;
    padding: 12px 0;
    color: #000 !important;
    font-weight: 700;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  &__wrap{
    text-align: right;
  }
  &__lk{
      display: inline-block;
      position: relative;
      color: $accent;
      font-weight: bold;
      padding-left: 80px;
      padding-top: 10px;
      padding-bottom: 15px;
      text-decoration: none;
    &:hover{
      color: $accent;
      text-decoration: underline;
    }
      &::before{
        content: '';
        background-image: url(../img/lk-img.jpg);
        position: absolute;
        left: 0;
        @include bgs;
        width: 68px;
        height: 100%;
        top: 0;
      }
  }
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){

   } 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}




 // мобильное меню

.toggle-mnu{
  display: block; 
  height: 26px;
  margin-top: 10px;
  margin-left: 0;
  position: relative;
  left: 0;
  padding: 3px  ;
  border-radius: 5px;
  top: 0; 
 

  span:after,
  span:before {
      content: "";
      position: absolute;
      left: 0; 
      top: 8px;
      }
  span:after{
      top: 16px;
  }
  span {
      position: relative;
      display: block;
  }
  span,
  span:after,
  span:before{
      width: 30px;
      height: 3px;
      background-color: $accent;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
  }
	&.on{
		opacity: 0;
	}
  &.on span {
      background-color: transparent;
  }
  &.on span:before {
      transform: rotate(45deg) translate(-1px, 0px);
  }
  &.on span:after {
      transform: rotate(-45deg) translate(5px, -6px);
    }
      }
.trop{
    
  &.hidden-mnu{
      position: fixed  !important; 
        visibility: hidden;
        right: -100%;
        top: 0;
        transition: all 0.6s ease ;
        z-index: 40; 
        width: 480px;
         max-width: 85%;
      .wrapp-hidden{
        background-color: #000;
        top: 0; 
        height: 100vh; 
        padding: 40px;
        
        overflow-y: auto;
         @include mq($until: bp480){
          padding: 20px 15px;
         }
        
      }
      ul{
      position: relative;
      // height: 65vh;
      // margin-right: -40px;
      }
      &.active{
        right: 0;
        visibility: visible;
        .toggle-mnu{
        	opacity: 1;
        }
      }

      .toggle-mnu{
        // position: relative;
        margin-left: -15%;
        position: absolute;
        opacity: 0;
        left: 0;
        right: auto;
        color: #FFF;
        top: 10px;
        z-index: 40;
        background-color: transparent;
        span,
        span:after,
        span:before{
          background-color: #FFF;
        }
        &.on {
        	opacity: 1;
                span {
                    background-color: transparent;
                }
              }
      }
    
      .btn{
      	 
      }
      .mnu-wrap{
      	padding-top: 40px;
      	overflow-y: auto;
      	height: 100vh;
      	margin: auto;
      	 
       
      }
       a{
        color: #FFF;
        span{
          color: #FFF;
        }
       }
      	li{
      		display: block;
      		margin: 0;
      		 
          border-top: 1px solid rgba(255,255 ,255 , .13);
          padding-bottom: 10px;
      		a{
      			// font-size: 16px; 
      			margin: 0;
      			color: #FFF;
      			// font-size: 20px;
            font-weight: 300;
            // padding-bottom: 10px;
            padding: 10px 0 5px;
            display: inline-block;
            text-transform: uppercase;
            // text-align: right;
            &:hover,
            &:focus{
              text-decoration: none;
              color: $accent;
            }
      		}
      	}
      
      .link-wrap{
      	// text-align: center;
      	margin: auto;
      	margin-bottom: 20px;
      }
     }
     .link-wrap{ 
      	 text-align: left;
         	// text-align: right;
      	 	a{
      	 		display: inline-block;
      	 		margin-right: 10px;
      	 		line-height: 1;
      	 		&.tel{
      	 			font-weight: 500;
      	 			color: #3c3c3c;
              font-size: 52px;
               @include mq($until: bp480){
                    font-size: 2em;

               }
      	 			.gray{
      	 				color: #5f5f5f;
      			 		text-decoration: none;
      	 			}
      	 		}
      	 	}
      		 
      	 }
      .link-wrap{
        text-align: right;
        a{
          margin-right: 0;
        }
      }
      p{
        margin-bottom: 0 !important;
      }
      .soc{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: flex-end; 
        &__link{
					 margin-left: 0;
					 margin-top: 0;
					 
				}

        
      }
   	 	.text-right{
   	 		margin-bottom: 15px;
   	 		.lang{
   	 			display: inline-block;
   	 			margin-bottom: 10px;
   	 			&___item:hover,
   	 			&__item.active
   	 			{
   	 				color: $accent;
   	 			}
   	 		}
   	 	}
   	 	&__link{
   	 		font-size: 20px;
   	 	}
   	 	p{
   	 		margin-bottom: 0px;
   	 	}
		}
.text-right{
	text-align: right;
}