.aside {
	display: block;
	border: 12px solid #e8edf2;
 	 
 	border-radius: 12px;
 	// overflow: hidden;
 	position: relative;
 	margin-top: 20px;
 	 @media only screen and (min-width : 767px){

 	margin-left: -45px;
 	 } 

 	padding: 12px 18px;
 	position: relative;
 	background: inherit;
 	// background-color: #fff;
 	&__head{
 		position: relative;
 		z-index: 20;
 	}
 	&__wrap{
 		position: relative;
 		z-index: 2;
	 	
	 	&::before{
	 		background: inherit;
	 		content: '';
	 		position: absolute; 
	 		left: -10%;
	 		top: -10%; 
	 		width: 120%;
	 		z-index: 1;
	 		height: 120%;
	 		box-shadow: inset 0 0 0 190px rgba(255, 255, 255, 0.7); 
	  	filter: blur(10px);

	 	}
 	}
 	&__status{
 		color: #48d84f;
 		font-weight: 900;
 		text-align: center;
 		display: inline-block;
 		position: relative;
 		z-index: 110;
 		
 	}
 	&__round{
 		width: 42px;
		height: 42px;
		background-color: #48d84f;
		border-radius: 50%;
		text-align: center;
		line-height: 42px;
		position: absolute;
		right: -32px;
		top: -32px;
		color: #FFF;
 	}
 	&__logo{
 		margin-top: -60px;
 		margin-bottom: 30px;
 	}
 	.h3{
 		font-weight: 700;
 		font-size: em(15);
 		span{
 			color: #48d84f;
			font-weight: 900;
 		}
		
 	}
 	&__body{
 		z-index: 2;
 		position: relative;
 	}
 	&__hr{
 		margin-top: 15px;
 		border-top: 1px dashed #000;
 		border-bottom: 1px dashed #000;
 		padding-top: 2px;
 		margin-bottom: 15px;
 	}
 	p{
 		font-size: em(13);
 		margin-bottom: 3px;
 	}
 	h4{
 		color: $def;
		font-size: rem(15px);
		line-height: (22/15);
 	}
 	&__big{
 		color: #ffac41;
		font-size: rem(45px);
		font-weight: 700;
		line-height: (56/45);
 	}
 	&__panel{
 		text-align: center;
 		position: relative;
 		height: 34px;
		border-radius: 10px;
		border: 1px dashed #141414;
		background-color: #fefefe;
		line-height: 34px;
		max-width: 214px;
		margin-bottom: 25px;
		display: block;
		text-decoration: none;
		color: #000;
		@include mt;
		&:hover,
		&:focus{
			background-color: #eee;
		}
 		&::before{
 			content: url(../img/coins.png);
 			margin-right: 20px;
 			position: relative;
 			top: 5px;
 		}
 	}
 	.form-wrap{
 		padding-top: 0;
 		padding-bottom: 10px;
 		&__item-wrap{
 			input{
 				margin-top: 0;
 				height: 34px;
 				font-size: 15px;
 				margin-top: 5px;
 				margin-bottom: 5px;
 				@include input-placeholder{
				color: $def;
				opacity: .5; 
			}
 			}
 		}
 		&__btn{
 			margin-top: 15px;
 		}
 	}
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 

	
	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}


.popover{

			position: relative;
			cursor: pointer;
	 		&::before{
	 			content: attr(data-content);
	 			position: absolute;
	 			width: 250px;
	 			left: 60%;
	 			margin-left: -125px;
	 			background-color: #fff;
	 			top: 120%;
	 			color: $def;
	 			display: block;
				// min-height: 100px;
				font-weight: normal;
				text-align: left;
				padding: 15px;
				border: 3px solid #ddd;
			 	@include mt;
			 	opacity: 0;
			 	z-index: -1;
	 		}
	 		&::after{
	 			content: '';
	 			position: absolute;
	 			left: 20%;
	 			top: 116%;
	 			@include mt;
	 			margin-left: -15px;
	 			margin-top: -8px;
	 			@include triangle(22px, #ddd, up);
	 			opacity: 0;
	 			z-index: -1;
	 		}
	 		&:hover{
	 			&::before,
	 			&::after{
	 				opacity: 1;
					z-index: 1;
	 			}
	 		}
 		}