.modal {
	display: block;
	max-width: 485px;
	position: relative;
	  .mfp-close{
	  	color: #FFF;
	  	font-size: 40px;
	  	font-weight: 100;
	  }

	// @include mq($until: bp1199) {}

	//  @include mq($until: bp991) {}

	//  @include mq($until: bp767) {}

	//  @include mq($until: bp480) {}

	//  @include mq($until: bp320) {}
}

a{
	outline: 0 !important;
}

.modal-form{
	position: relative;
}

#foot-dialog{
	h3{
		font-size: em(36);
	}
}