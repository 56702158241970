 
 
/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px)
	{/**/}


/* Medium Devices, Desktops */
@media only screen and (max-width : 991px)
	{/**/}


/* Small Devices, Tablets */
@media only screen and (max-width : 767px)
	{/**/}


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px)
	{.col-480{
		width: 100%;
	}
}


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px)
	{/**/}


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px)
	{/**/}


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px)
	{/**/}


/* Small Devices, Tablets */
@media only screen and (min-width : 768px)
	{/**/}


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px)
	{/**/}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px)
	{/**/}

