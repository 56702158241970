.s-video {
	display: block;
 
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}
.slider{
		max-width: 600px;
		margin: auto;

	&__video-wrap{
		@include block(273, 153);
		iframe{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		margin-top: 5px;
		margin-bottom: 15px;
	}
	&__item{
		padding: 0 15px;
	}
	&__caption{
		@include ellips();
	}
}
