.footer {
position: absolute;
// padding-top: 20px;
left: 0;
bottom: 60px;
width: 100%;
color: #FFF;
a{
	color: #fff;
	font-weight: normal;
}
.container{
	background-color: rgba(0,0,0, .82);
	border-radius: 12px;
	padding: 35px 40px;
}
h4{
	text-transform: uppercase;
	font-size: em(15);
}

li{
	margin-bottom: 10px;
}
&__banners{
	margin: 0 -5px;
}
&__banner{
	margin: 0 5px 5px;
	display: inline-block;
}
&__text{
	font-size: 13px;
	font-weight: 300;
	line-height: 22px;
	text-transform: uppercase;
	margin-bottom: 20px;
	 @media only screen and (min-width : 991px){
	margin-left: 30%;
	 	
	 } 
}	
 @media only screen and (max-width : 1199px){
 	.container{ 
		padding: 25px 20px;
	}
 } 

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}


