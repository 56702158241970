.content {
	display: block;
 	max-width: 460px;

 	&__head{
 		margin-bottom: 25px;
 		a{
 			color: $def;
			font-family: $default-font;
			font-size: rem(18px);
			font-weight: 700;
			line-height: 1;
			text-decoration: none;
			border-bottom: 2px solid;
			display: inline-block;
			margin-bottom: 10px;
			&:hover{
				border-color: transparent;
			}
 		}
 	}
 	&__date{
 		font-size: 13px;
 	}
 	p{
 		line-height: (25/15);
 		a{
 			display: inline-block;
 			margin-left: 15px;
 		}
 	}
 	&__item{
 		margin-bottom: 28px;
 	}
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}

