.s-lk {
	display: block;
	margin-top: 15px;
	// padding-bottom: 90px;
	@media only screen and (min-width : 767px){
	padding-right: 30px;

	} 
	table{
		// margin-right: 95px;
		max-width: 100%;

		min-width: 420px;
	}
	.table-wrap{
		overflow-x: auto;
	}
 	td {
			vertical-align: middle;
			padding: 10px 0;
		 border-bottom: 1px solid #69a5cf;
			min-height: 35px;
			$w: (460/100%);
			&:nth-child(1){
					width: (20/$w);
					padding-right: 15px;
					font-weight: bold;
			}
			&:nth-child(2){
				width: (160/$w);
				padding-right: 15px;
				font-weight: bold;
			}
			&:nth-child(3){
				width: (150/$w);
				padding-right: 15px;
				position: relative;
				font-size: rem(11px);
				font-weight: 700;
				padding-left: 25px;
			}
			&.before{
				
				&::before{
					content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAUCAYAAAC9BQwsAAACUklEQVQ4T6WTT0hUURTGf+c5ThD9g2oTLXxvnLlDQhYlJGZFfyEJDCqCgqBNbguagsRCbJEFbm0TBAVBQUIY9JfKxKAiXSjzZvKNi2hTQVEE6fhOvHlPeyKuuqvDvef7vnO+c48QO6PUJav43SVwEmRl+KTfFG5Ms7i9jtHJmXSJAws4dxX2KVwWxK3AUCNwQeBRBu/wPGCRmi0+1pCgezOUnswltPco8tjCb0wz8SZ4m1XM45wTaDN4dhw0E7s4JYXeLN6VOUAX5xLQavA2LAAcBvoMXpD3T/G/gWUmdyaobgFZF7k6VmaqP0Hy+YKKCmcFrLASHQuBFQJV8AWuzilVwSqSeqjodkE6fBK9WdyfASyPWWpRblO0U5CXacb3S0gCLs55IGh6t8F7vYA5W4GnChcDZ6VI7TIf/5Mg3RnGu0KizCplKhe6V91tKHwN4gKpdkVzFtZayWMfEeRWAlan8H5EFdwHNkfK7wzewSAex1lehi+KHpcCdqePtGTxNsWGPQw6EplTH59tHue9hfaLS+oaaKPBa5oB5knlBK38EOCMweuJkQ6CDImLcxrIZfDWBDMIe7E7QY5Gc7yTodQRfnikgPMZ6A56XC/ICOg2Q2kg6vEBsDFS+WDwDoT3djPIK0XrK+PIk3phoUsEqynNxz8uzi5BToUqet3gPStSu0jxB33kV5bxHRHQNoK8BQaS+MdsJr7HZ1miZsUk1m2gWdGGLCV3dq2ifewDqkBuAgFRcBpATwDTFn7rvH0MMiLmwKxDQDoCFoF7SfyeeCV/AXXz5gkcF1sBAAAAAElFTkSuQmCC);
					left: 0;
					top: 50%;
					margin-top: -10px;
					position: absolute;


				}
			}
			 a{
			 
			 	color: #000;
			 }

		}
		.form-wrap{
			form{

			margin-left: 0;
			}
			input{
				color: #000;
				@include input-placeholder{
				color: #000; 
				opacity: .7; 
			}
		&:focus{ 
			opacity: 1;
		 
		 
		}
			}
		}
		&__h2{
			color: #434343; 
			font-size: 30px;
			font-weight: 700; 
			margin-bottom: 30px;
		}
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}
.lk-nav{
	padding-bottom: 20px;
	&__link{
		color: #000;
		position: relative;
		&:hover,
		&.current{
			color: #2e6d8e;
			text-decoration: none;
		}

	}
	&__item{
		position: relative;
		&::after{
			content: '';
			position: absolute;
			right: 0;
			width: 2px;
			height: 85%;
			top: 0;
			background-color: #000;
		}
		&:last-child::after{
			display: none;
		}
	}
}