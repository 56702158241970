.header{
	
	&__btn{
		width: 164px;
		height: 38px;
		margin-left: auto;
		margin-right: auto;
	 @media only screen and (max-width : 991px){
			margin-top: 15px;
		// ?margin-bottom: 15px;
	 } 
	} 
	&__container{
		max-width: 880px;
		margin: auto;
	}
	.col{

	}
  @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 


	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
 }
 .logo{
 	display: inline-block;
 }