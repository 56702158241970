.mixin-wrap {
	display: block;
}


	input{
		width: 100%;
		 
		border-radius: 0;
		height: 51px;
		border-radius: 10px;
		background-color: #eeeeee;
		border: none; 
		margin-top: 30px;
		padding: 0 18px; 
		font-weight: 400; 
	 font-weight: bold;
	 color: #186185;
		@include mt;
		@include input-placeholder{
				color: #186185; 
				opacity: .7; 
			}
		&:focus{ 
			opacity: 1;
		 
		 
		}
		
	}


.form-wrap{ 
	padding: 15px 0 165px; 
	@media only screen and (max-width : 991px){
		padding-top: 0;
			padding-bottom: 69px;

	}
	margin: auto;
	max-width: 730px;
	color: #FFF; 
	@include clearfix; 
	form{
		margin: auto;
		max-width: 310px; 
	}
	 
 	&__btn{
 		margin-top: 35px;
 		width: 100%;
 		margin-bottom: 10px;
 		margin-left: auto;
 		margin-right: auto;
 	}
 &__footer{
 	margin-top: 30px;

 }
 &__link{
 	font-size: 20px;
	font-weight: 700;
	margin: 0 5px;
	position: relative;
	&:first-child{
		&::before{
			content: '';
			right: -6px;
			height: 100%;
			width: 2px;
			background-color: #186185;
			position: absolute;
			bottom: 0;
		}
	}
	&:hover,
	&:focus{
		color: #186185;
	}
 }
}



.soc{
	margin: 0 -5px;
	&__item{
		display: inline-block;
		text-decoration: none !important;
		margin: 0 5px;
		vertical-align: middle;
		@include mt;
		&:hover{
		 	// box-shadow: 0 0 33px 0px rgba(255, 83, 0, 0.16);
			opacity: .6 ;
		}
	}
	svg{
		width: 31px;
		height: 31px;
	}
		path,
		polygon{
			fill: $accent !important;
		}

}
.politic{
		opacity: 0.7;
		color: #ffffff;
		font-family: Circe;
		font-size: 12px;
		font-weight: 400;
		// Text style for "согласие"
		position: relative;
		max-width: 250px;
		padding-left: 40px;
		margin: auto;
		margin-top: 5px;
		text-align: left;
		&::before{
			left: 0;
			content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABEElEQVRIS+2WPQoCMRCF3/MKgvfwBqK1va2dN7DwAp5AS+9grXcQtBJbC0Gv4MjIRnZDzM9uWBDcNuF9mZc3k+VjNH2CJNr4RIQK7O43nTZ4yvoDszr9+5ZqCAH0SN5c1mStsICtAIwBDEhebGg2YAk2KyBXFzQL0AEzhR1I9stVNgZ6YHcAQ5LHbMBUmIKDFYrIAsDWPmkdWBAoIksAcwAVe+rCvMASzFzBGwrgBECjb9JYWbediG4LEVl/Ed0BmFhCzoAkNb7HNltH+03TeI4Zut7QRECdze0Dx6RUZ6PrzpJhwZSak8aOrcaWlgViBnNWoIqFnp7swBjB0J5gaEICqet/YKpjwf0fS9v81X8BkAce0Kg5eEoAAAAASUVORK5CYII=);
			position: absolute;
		}	

		a{
			color: #FFF;
			text-decoration: underline;
		}
}