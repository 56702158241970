html{
-webkit-font-smoothing: antialiased;
  background-position:  center 0px;
  background-repeat: no-repeat;
  // background-image: url(../screen/lk-1.jpg);
}
body{
  // opacity: .7;
  // position: relative;
  // overflow: hidden;
  // min-height: 10000px;
  height: 100%;
  min-height: 100%;
 }
 .slick-slide{
  height: auto;
 }
   @media only screen and (min-width : 1199px){
      .container{
        padding: 0;
      }

   }
table{
  width: 100%;
} 
html{
  height: 100%;
  }
body{
  font-size: 15px;
  min-width: 320px;
  position: relative;
  line-height: 1.4;
  font-family: $default-font;
  color: $def;
  // overflow-x: hidden;
  // opacity: 1;
  
  &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include mt;
      background-color: #000000;
      opacity: 0;
      z-index: -1;
    }
  &.fixed{
    overflow: hidden;
    position: absolute;
    width: 100%; 
    left: 0;
    top: 0;
    &::before{
      z-index: 20;
      // opacity: 1;
      opacity: 0.76;
    }
  }
}
li{
  list-style-type: none;
}
iframe{
  border: none;
}
.main-container{
  margin: auto;
  max-width: 765px;
}
.main-wrapper{
  margin: auto;
  max-width: 1920px;
  position: relative;
  overflow: hidden; 
    background-image: 
                      url(../img/footer-bg.png),
                      url(../img/main-bg.jpg); 
  background-size:    inherit,    inherit;
  background-position:   50% 100%,    50%  50px;
  background-repeat:    no-repeat  ;
  min-height: 100%;
  padding-bottom: 590px;
  
  @media only screen and (min-width : 480px){

  padding-bottom: 490px;

  }
   
  @media only screen and (min-width : 767px){
  padding-bottom: 420px;

  }

}

h1,
h2{
  font-size: rem(45px);
  font-weight: 700;
  line-height: (77/45);
  margin-right: 20px;
   @media only screen and (max-width : 991px){
    font-size: rem(40);
  line-height: (67/45);

   } 
}

.row-head{
  margin: 0;
  p{
    font-size: rem(20px);
    line-height: (25/20);
    margin-bottom: 25px;
  }
}

.slick-slide img{
  display: inline}
.image-source-link {
  color: #98C3D1;
}

.mfp-with-zoom {
  .mfp-container, &.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    
    transition: all 0.3s ease-out;
  }
  &.mfp-ready {
    .mfp-container {
      opacity: 1;
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    .mfp-container, &.mfp-bg {
      opacity: 0;
    }
  }
}

.slick-slide{
  outline:  0 !important;
}
.slick-arrow{
  position: absolute;
  top: 50%;
  font-size: 36px;
  margin-top: -10px;
  z-index: 2;
  cursor: pointer;
  i{
  line-height: 1;
    
  }

  &::before{
    content: '';
    position: absolute; 
    border: 1px solid #141414;
    height: 50px;
    width: 50px;
    @include transform( rotate(-45deg));
  }
  cursor: pointer;
  &.l{
    left: -45px;
    &::before{
    border-bottom: none;
    border-right: none;
    }
  }
  &.r{
    right: -5px;
     &::before{
      
    // border-bottom: none;
    border-top: none;
    // border-right: none;
    border-left: none;
      
    } 
  }
  svg{
    width: 17px;
    height: auto;
  }
   
  @media only screen and (max-width : 767px){
    margin-top: -30px;
     &::before{
      height: 30px;
      width: 30px;
    }
      &.l{
      left: -5px; 
    }
  &.r{
    right: 25px;  
    } 
  
   } 
}

 input,
textarea{
  @include input-placeholder{
    color: #666;
  };
}
 

/* start state */

.my-mfp-zoom-in {
  .zoom-anim-dialog {
    opacity: 0; 
    transition: all 0.2s ease-in-out; 
    transform: scale(0.8);
  }
  &.mfp-ready .zoom-anim-dialog {
    opacity: 1; 
    transform: scale(1);
  }
  &.mfp-removing .zoom-anim-dialog { 
    transform: scale(0.8);
    opacity: 0;
  }
  &.mfp-bg {
    opacity: 0; 
    transition: opacity 0.3s ease-out;
  }
  &.mfp-ready.mfp-bg {
    opacity: 0.8;
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}
 
.my-mfp-slide-bottom {
  .zoom-anim-dialog {
    opacity: 0; 
    transition: all 0.2s ease-out; 
    transform: translateY(-20px) perspective(600px) rotateX(10deg);
  }
  &.mfp-ready .zoom-anim-dialog {
    opacity: 1; 
    transform: translateY(0) perspective(600px) rotateX(0);
  }
  &.mfp-removing .zoom-anim-dialog {
    opacity: 0; 
    transform: translateY(-10px) perspective(600px) rotateX(10deg);
  }
  &.mfp-bg {
    opacity: 0; 
    transition: opacity 0.3s ease-out;
  }
  &.mfp-ready.mfp-bg {
    opacity: 0.8;
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}
 


*{
  margin: 0;
  padding: 0  } 
.text-center{
  text-align: center}
.res-i{
  max-width: 100%
}
strong,
.strong{
  font-weight: bold}
.light{
  font-weight: 300}
.accent{
  @include  accent;
}

.table{
  display: table}
.tr{
  display: table-row  }
.td{
  display:  table-cell
}
