.s-text {
	display: block;
	padding-bottom: 30px;
	
	 @media only screen and (min-width : 1199px){
	 	padding-left: 15px;
	 } 
 	h4{
 		color: #69a5cf;
		font-size: rem(20px);
		font-weight: 700;
		line-height: (25/20);
		margin-bottom: 15px;
		margin-top: 5px;
 	}
 	li{
 		margin-bottom: 5px;
 	}
 	ul{
 		margin-bottom: 25px;
 	}

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
}
.row-head{
	
}