.s-top {
	display: block;
	.h4{
		font-family: $default-font;
		font-size: 20px;
		font-weight: 700;
		margin-top: 12px;
		margin-bottom: 20px;
	}
 	.tabs{
 		border-radius: 10px;
		background-color: #eeeeee;
		padding: 20px 20px 0px;
		margin-bottom: 10px;

		&__caption{
			margin: 0 -5px;
			margin-bottom: 5px;
		}
		&__content{
			overflow-x: auto;
		}
		&__btn{
			display: inline-block;
			height: 27px;
			border-radius: 5px;
			text-align: center;
			line-height: 27px;
			color: #186185;
			margin: 0  5px;
			padding: 0 5px;
			&:hover,
			&.active{
				color: #FFF;
				background-color: #5e9aca;
			}
		}
		table{
			width: 100%;
			// padding-right: 15px;
			max-width: 700px;
			min-width: 500px;
		}
		td,
		th{
			vertical-align: middle;
			padding: 10px 0;
			font-size: em(13);
			$w: (700/100%);
			&:nth-child(1){
					width: (180/$w);
					padding-right: 15px;
			}
			&:nth-child(2){
				width: (130/$w);
				padding-right: 15px;
			}
			&:nth-child(3){
				width: (130/$w);
				padding-right: 15px;
			}
			&:nth-child(4){
				width: (130/$w);
				padding-right: 15px;
			}
			&:nth-child(5){
					// width: (180/$w);
					text-align: right;
			} 

		}
		th{
			font-weight: 700;
			padding-bottom: 15px;
		}
		tr:last-child td{
			border: none;
		}
		td{
			border-bottom: 1px solid #fff;
			&:nth-child(1),
			&:nth-child(5){
					font-size: rem(15);
					font-weight: 700;
			}
		}
	 @media only screen and (max-width : 1199px){} 

	 @media only screen and (max-width : 991px){} 

	 @media only screen and (max-width : 767px){} 

	 @media only screen and (max-width : 479px){} 

	 @media only screen and (max-width : 320px){} 
 	}
}

.tabs{
	&__btn{
		cursor: pointer;
		@include mt;
	}
	&__content{
		&.active{
			display: block;
		}
		// display: block;
		display: none;
	}
}