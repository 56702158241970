 
 
@include font-face (Lato, "../fonts/LatoRegular/LatoRegular", $file-formats:   woff ttf eot  );
@include font-face (Lato, "../fonts/LatoLight/LatoLight", 300, $file-formats:  woff ttf eot);
@include font-face (Lato, "../fonts/LatoBold/LatoBold", 700, $file-formats:  woff ttf eot );
@include font-face (Lato, "../fonts/Lato-Black/Lato-Black", 900,  $file-formats:   woff ttf eot  );

$default-font: Lato, sans-serif;
$accent: #ce2d6d;
$yel: #373a41;
$def: #373a41;
@mixin accent  { 
  color: $accent;
}
@mixin yel  {
  color: $yel; 
}
.yel {
  @include yel
}
.accent {
  @include accent
}

@mixin bgs{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;}
@mixin mt{
  transition: all 0.2s ease ;}
@mixin c-white{
  color: #fff;}
@mixin c-dark{
  color: #000  ;}


@mixin fontello($icon, $fz){
  content: $icon;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  font-size: $fz;
}
@mixin awe($icon, $fz){
  content: $icon;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-size: $fz;
}
// михин многоточия
@mixin ellips{
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  // display: block;
  // display: inline-block;
}

// обсалютное центрирование
@mixin a-cent($left, $top){
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -$top;
  margin-left:-$left ;

  // Breakpoints
}
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
// масштвбирование блока пропорционально
@mixin block($w, $h){
  display: block;
  position: relative;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: ($h/$w *100%);
    }
}
  // Breakpoints
//--------------------------------------
$bp320:  320px;
$bp480:  480px;
$bp767: 767px; // Используется в .header
$bp991: 991px;
$bp1199: 1199px;

/// Breakpoints
$mq-breakpoints: (
  bp320:  $bp320,
  bp480:  $bp480,
  bp767: $bp767,
  bp991: $bp991,
  bp1199: $bp1199
);


